
#id62fa7cbdfc8b2a0012c15bbe {
  #i8fj {
padding : 10px; 
min-height : 100px; 
height : 3196px; 
__bg-type : color; 
background-image : linear-gradient(#56575C,#56575C); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ijk5 {
padding : 10px; 
min-height : 100px; 
height : 300px; 
width : 100%; 
position : relative; 
} 
#ixax {
padding : 10px; 
min-height : 100px; 
height : 286px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 100%; 
} 
#i0wk {
height : 100%; 
width : 100%; 
top : 0; 
right : 0; 
position : absolute; 
} 
.gjs-lofty-navbar-center-topnav {
position : relative; 
overflow-x : hidden; 
overflow-y : hidden; 
background-color : rgb(41, 42, 46); 
height : 150px; 
} 
.gjs-lofty-navbar-center-topnav p {
float : left; 
text-align : center; 
padding-top : 14px; 
padding-right : 16px; 
padding-bottom : 14px; 
padding-left : 16px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.gjs-lofty-navbar-center-topnav p.left {
color : white; 
} 
.gjs-lofty-navbar-center-topnav-centered-left {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-centered-right {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-right {
float : right; 
position : relative; 
top : 50%; 
font-size : 17px; 
} 
.gjs-lofty-navbar-center-topnav-image {
position : absolute; 
top : 10%; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span {
transform : rotate(45deg); 
left : 120px; 
top : -30px; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::before {
top : 0px; 
transform : rotate(0deg); 
background-color : black; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::after {
top : 0px; 
background-color : black; 
transform : rotate(90deg); 
} 
#gjs-lofty-navbar-center-menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.gjs-lofty-navbar-center-menu__btn {
position : absolute; 
top : 45%; 
left : 5%; 
width : 26px; 
height : 26px; 
cursor : pointer; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-menu__btn > span, .gjs-lofty-navbar-center-menu__btn > span::before, .gjs-lofty-navbar-center-menu__btn > span::after {
display : block; 
position : absolute; 
width : 100%; 
height : 2px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__btn > span::before {
content : ""; 
top : -8px; 
} 
.gjs-lofty-navbar-center-menu__btn > span::after {
content : ""; 
top : 8px; 
} 
.gjs-lofty-navbar-center-menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100%; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.gjs-lofty-navbar-center-dropbtn {
background-color : rgb(41, 42, 46); 
color : white; 
padding-top : 12px; 
padding-right : 12px; 
padding-bottom : 12px; 
padding-left : 12px; 
font-size : 14px; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-bottom-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-bottom-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
cursor : pointer; 
max-width : 100%; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown {
position : relative; 
top : 20%; 
} 
.gjs-lofty-navbar-center-dropdown-content {
display : none; 
position : absolute; 
background-color : gray; 
min-width : 60px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-dropdown-content a {
color : white; 
padding-top : 4px; 
padding-right : 6px; 
padding-bottom : 4px; 
padding-left : 6px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : block; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown-content a:hover {
background-color : rgb(241, 241, 241); 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropdown-content {
display : block; 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropbtn {
background-color : rgb(41, 42, 46); 
} 
#iulh4 {
padding : 10px; 
min-height : 100px; 
height : 1476px; 
position : relative; 
display : flex; 
} 
#iskol {
padding : 10px; 
min-height : 100px; 
height : 93%; 
position : relative; 
width : 60%; 
left : 5%; 
top : 5%; 
__bg-type : color; 
background-image : linear-gradient(#C4C4C4,#C4C4C4); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#il2jp {
padding : 10px; 
min-height : 100px; 
height : 393px; 
position : relative; 
width : 35%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5ju8gxu.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#itt4x {
padding : 10px; 
min-height : 100px; 
display : flex; 
position : relative; 
} 
#i8dn6 {
padding : 10px; 
min-height : 100px; 
width : 50%; 
position : relative; 
} 
#i7eni {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 50%; 
} 
#i5irz {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
} 
#ivggr {
padding : 10px; 
min-height : 0; 
height : 102px; 
width : 100%; 
} 
#ivsc7 {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#i47ze {
padding : 0 0 0 0; 
min-height : 0; 
height : 0; 
width : 50%; 
border : 1px solid #D6A419; 
left : 25%; 
position : relative; 
} 
#ixflv {
padding : 10px; 
min-height : 100px; 
height : 231px; 
position : relative; 
} 
#ib9mt {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#iu9ft {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 155px; 
} 
#iafs7 {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#if3uf {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 16px; 
color : #56575C; 
} 
#i0vjo {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#iyw7tj {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#ia5u8r {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 14px; 
color : #56575C; 
} 
#ioz3nl {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 155px; 
} 
#i2tig6 {
padding : 10px; 
min-height : 100px; 
height : 231px; 
position : relative; 
} 
#i32zid {
white-space : pre-wrap; 
} 
#iyqvpl {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#isflqg {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#is1op3 {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 18px; 
color : #56575C; 
} 
#ip3ng3 {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 343px; 
} 
#iy8bhi {
padding : 10px; 
min-height : 100px; 
height : 411px; 
position : relative; 
} 
#ijdt4k {
white-space : pre-wrap; 
} 
#ip7h93 {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#i5xsb3 {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#ijxrik {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 16px; 
color : #56575C; 
} 
#in8bzj {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 105px; 
} 
#i37k8k {
padding : 10px; 
min-height : 100px; 
height : 167px; 
position : relative; 
} 
#io761z {
white-space : pre-wrap; 
} 
#iohh9g {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#ijmwrn {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#iqer13 {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 16px; 
color : #56575C; 
} 
#irtdos {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 116px; 
} 
#isa4mk {
padding : 10px; 
min-height : 100px; 
height : 174px; 
position : relative; 
} 
#i48ify {
white-space : pre-wrap; 
} 
#i545qf {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#ic11df {
padding : 0 0 0 0; 
min-height : 0; 
height : 0; 
width : 50%; 
border : 1px solid #D6A419; 
left : 25%; 
position : relative; 
} 
#iz9qir {
padding : 10px; 
min-height : 0; 
height : 73px; 
width : 100%; 
} 
#i8ws06 {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
} 
#iaqq06 {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#i1groc {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#ifccq8 {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 16px; 
color : #56575C; 
} 
#i71avp {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 207px; 
} 
#iwkent {
padding : 10px; 
min-height : 100px; 
height : 273px; 
position : relative; 
} 
#idf33t {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#i5r2wf {
padding : 0 10px 0 10px; 
min-height : 0; 
width : 90%; 
height : 0; 
border : 1px solid #E5E5E5; 
} 
#iqzcrj {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
line-height : 16px; 
color : #56575C; 
} 
#iph7ew {
padding : 10px; 
min-height : 100px; 
position : relative; 
height : 115px; 
} 
#iemnxq {
padding : 10px; 
min-height : 100px; 
height : 179px; 
position : relative; 
} 
#ilparc {
padding : 10px; 
min-height : 100px; 
height : 93%; 
position : relative; 
width : 55%; 
left : 2%; 
top : 5%; 
__bg-type : color; 
background-image : linear-gradient(#C4C4C4,#C4C4C4); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#i1jsxj {
padding : 10px; 
min-height : 100px; 
height : 393px; 
position : relative; 
width : 40%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5jwhc4p.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
left : 2%; 
} 
#i9d40k {
padding : 10px; 
min-height : 100px; 
height : 660px; 
position : relative; 
display : flex; 
} 
#io13ji {
white-space : pre-wrap; 
} 
#iod7bn {
white-space : pre-wrap; 
} 
#ick86t {
white-space : pre-wrap; 
} 
#ipjf3a {
padding : 10px; 
min-height : 100px; 
height : 300px; 
position : relative; 
width : 40%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5k1h58p.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
left : 2%; 
} 
#idp5jx {
white-space : pre-wrap; 
} 
#i2808z {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#io5l7g {
padding : 0 0 0 0; 
min-height : 0; 
height : 0; 
width : 50%; 
border : 1px solid #D6A419; 
left : 25%; 
position : relative; 
} 
#i0ofpg {
padding : 10px; 
min-height : 0; 
height : 73px; 
width : 100%; 
} 
#i93i73 {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
} 
#igof0t {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#i7x1zj {
padding : 10px; 
min-height : 100px; 
height : 213px; 
position : relative; 
} 
#ihssh9 {
padding : 10px; 
min-height : 100px; 
height : 93%; 
position : relative; 
width : 50%; 
left : 5%; 
top : 5%; 
__bg-type : color; 
background-image : linear-gradient(#C4C4C4,#C4C4C4); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#iaqqq9 {
padding : 10px; 
min-height : 100px; 
height : 457px; 
position : relative; 
display : flex; 
} 
#izejfs {
white-space : pre-wrap; 
} 
#i7ne5g {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#izmnq1 {
padding : 0 0 0 0; 
min-height : 0; 
height : 0; 
width : 50%; 
border : 1px solid #D6A419; 
left : 25%; 
position : relative; 
} 
#iqnwly {
padding : 10px; 
min-height : 0; 
height : 73px; 
width : 100%; 
} 
#i8wcay {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
} 
#i66e7f {
padding : 10px; 
display : block; 
color : #56575C; 
font-family : Times New Roman, Times, serif; 
font-weight : 600; 
} 
#i6zsql {
padding : 10px; 
min-height : 100px; 
height : 115px; 
position : relative; 
} 
#isofoo {
padding : 10px; 
min-height : 100px; 
height : 60%; 
position : relative; 
width : 50%; 
left : 1%; 
top : 5%; 
__bg-type : color; 
background-image : linear-gradient(#C4C4C4,#C4C4C4); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#i9b58g {
padding : 10px; 
min-height : 100px; 
height : 375px; 
position : relative; 
width : 40%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5k1x7og.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
left : 2%; 
} 
#i7xfof {
padding : 10px; 
min-height : 100px; 
height : 457px; 
position : relative; 
display : flex; 
float : none; 
} 
#if6id8 {
padding : 10px; 
min-height : 100px; 
height : 127px; 
position : relative; 
width : 100%; 
display : flex; 
} 
#iacgmf {
padding : 10px; 
min-height : 100px; 
height : 134px; 
position : relative; 
} 
#iu9123 {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
color : #FFFFFF; 
} 
#iaypss {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#i8osim {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#i36x8h {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#i40iea {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#ie87k8 {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#iha4e8 {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#iaq2t7 {
color : rgb(255, 255, 255); 
font-family : "Times New Roman", Times, serif; 
text-align : center; 
white-space : pre-wrap; 
} 
#ixamfy {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#i9tjsk {
padding : 10px; 
min-height : 100px; 
width : 50%; 
position : relative; 
display : flex; 
} 
#iqx8xl {
color : black; 
height : 100%; 
width : auto; 
float : right; 
} 
#il22am {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D1AF7C; 
width : 0; 
height : 100%; 
max-width : 0; 
} 
#igni1j {
padding : 0 10px 10px 10px; 
display : block; 
color : #D1AF7C; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#i88ni3 {
padding : 10px; 
display : block; 
float : none; 
text-align : right; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
color : #D6A419; 
} 
#im1qvd {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Times New Roman, Times, serif; 
color : #FFFFFF; 
} 
@media only screen and (max-width: 480px) {#iulh4 {
display : block; 
width : 100%; 
height : 1857px; 
}} 
@media only screen and (max-width: 480px) {#iskol {
width : 100%; 
right : 0; 
left : 0; 
bottom : 0; 
padding : 10px 0 10px 0; 
top : 0; 
height : 86%; 
}} 
@media only screen and (max-width: 480px) {#il2jp {
display : none; 
}} 
#ilcz9l {
padding : 10px; 
min-height : 100px; 
height : 393px; 
position : relative; 
width : 25%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5ju8gxu.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
display : none; 
} 
@media only screen and (max-width: 480px) {#ilcz9l {
display : block; 
width : 100%; 
padding : 10px 0 10px 0; 
height : 200px; 
}} 
@media only screen and (max-width: 480px) {#i1jsxj {
width : 100%; 
height : 200px; 
padding : 10px 0 10px 0; 
right : 0; 
left : 0; 
}} 
@media only screen and (max-width: 480px) {#i8fj {
height : 4276px; 
right : 0; 
left : 0; 
}} 
@media only screen and (max-width: 480px) {#i9d40k {
display : block; 
height : 997px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#isa4mk {
height : 183px; 
}} 
@media only screen and (max-width: 480px) {#ilparc {
width : 100%; 
left : 0; 
right : 0; 
top : 0; 
height : 75%; 
}} 
@media only screen and (max-width: 480px) {#i7xfof {
display : block; 
width : 100%; 
height : 501px; 
}} 
@media only screen and (max-width: 480px) {#irtdos {
height : 120px; 
}} 
@media only screen and (max-width: 480px) {#i37k8k {
height : 252px; 
}} 
@media only screen and (max-width: 480px) {#in8bzj {
height : 135px; 
}} 
@media only screen and (max-width: 480px) {#iy8bhi {
height : 516px; 
}} 
@media only screen and (max-width: 480px) {#ioz3nl {
height : 197px; 
}} 
@media only screen and (max-width: 480px) {#i2tig6 {
height : 261px; 
}} 
@media only screen and (max-width: 480px) {#iu9ft {
height : 165px; 
}} 
@media only screen and (max-width: 480px) {#iwkent {
height : 323px; 
}} 
@media only screen and (max-width: 480px) {#iemnxq {
height : 235px; 
}} 
@media only screen and (max-width: 480px) {#iph7ew {
height : 146px; 
}} 
@media only screen and (max-width: 480px) {#iz9qir {
height : 129px; 
}} 
@media only screen and (max-width: 480px) {#i8ws06 {
height : 142px; 
}} 
@media only screen and (max-width: 480px) {#i71avp {
height : 259px; 
}} 
#i7alth {
padding : 10px; 
min-height : 100px; 
height : 300px; 
position : relative; 
width : 40%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5k1h58p.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
left : 2%; 
display : none; 
} 
@media only screen and (max-width: 480px) {#i7alth {
display : block; 
right : 0; 
left : 0; 
width : 100%; 
height : 250px; 
}} 
@media only screen and (max-width: 480px) {#ipjf3a {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iaqqq9 {
display : block; 
height : 755px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ihssh9 {
width : 100%; 
top : 0; 
right : 0; 
bottom : 0; 
left : 0; 
height : 65%; 
}} 
@media only screen and (max-width: 480px) {#i7x1zj {
height : 331px; 
}} 
@media only screen and (max-width: 480px) {#i9b58g {
width : 100%; 
height : 200px; 
right : 0; 
left : 0; 
}} 
@media only screen and (max-width: 480px) {#isofoo {
width : 100%; 
top : 0; 
right : 0; 
left : 0; 
bottom : 0; 
height : 50%; 
}} 
@media only screen and (max-width: 480px) {#if6id8 {
height : 155px; 
}} 
@media only screen and (max-width: 480px) {#ixax {
height : 370px; 
}} 
@media only screen and (max-width: 480px) {#iacgmf {
height : 182px; 
}} 
@media only screen and (max-width: 480px) {#if3uf {
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#ia5u8r {
text-align : left; 
line-height : 16px; 
}} 
@media only screen and (max-width: 480px) {#is1op3 {
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#ip3ng3 {
height : 431px; 
}} 
@media only screen and (max-width: 480px) {#iqer13 {
line-height : 14px; 
}} 
@media only screen and (max-width: 480px) {#ijk5 {
height : 150px; 
}} 
@media only screen and (max-width: 992px) {#iulh4 {
height : 1547px; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i37k8k {
height : 208px; 
}} 
@media only screen and (max-width: 992px) {#in8bzj {
height : 118px; 
}} 
@media only screen and (max-width: 992px) {#iy8bhi {
height : 428px; 
}} 
@media only screen and (max-width: 992px) {#isa4mk {
height : 180px; 
}} 
@media only screen and (max-width: 992px) {#ip3ng3 {
height : 361px; 
}} 
@media only screen and (max-width: 992px) {#i1jsxj {
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#il2jp {
width : 300px; 
height : 200px; 
right : 35px; 
position : absolute; 
}} 
@media only screen and (max-width: 992px) {#iemnxq {
height : 204px; 
}} 
@media only screen and (max-width: 992px) {#i9d40k {
height : 736px; 
}} 
@media only screen and (max-width: 992px) {#iwkent {
height : 307px; 
}} 
@media only screen and (max-width: 992px) {#i71avp {
height : 243px; 
}} 
@media only screen and (max-width: 992px) {#i8fj {
height : 3342px; 
}} 
@media only screen and (max-width: 992px) {#i9b58g {
height : 200px; 
}} 
@media only screen and (max-width: 992px) {#iskol {
width : 80%; 
left : 0; 
}} 
@media only screen and (max-width: 992px) {#i7x1zj {
height : 280px; 
}} 
@media only screen and (max-width: 992px) {#ijk5 {
height : 200px; 
}} 
#iweju6 {
white-space : pre-wrap; 
} 
#iecnzg {
white-space : pre-wrap; 
} 
#inkya1 {
white-space : pre-wrap; 
} 
#in7n4f {
white-space : pre-wrap; 
} 
#i8bnz7 {
white-space : pre-wrap; 
} 
#iqmimv {
white-space : pre-wrap; 
} 
#ic2b18 {
white-space : pre-wrap; 
} 
#iap753 {
white-space : pre-wrap; 
} 
#if0f3z {
white-space : pre-wrap; 
} 
#iy658g {
white-space : pre-wrap; 
} 
#ii0955 {
white-space : pre-wrap; 
} 
#ibu4kn {
white-space : pre-wrap; 
} 
#ilir1u {
white-space : pre-wrap; 
} 
#ij2sg4 {
white-space : pre-wrap; 
} 
#id2vss {
white-space : pre-wrap; 
} 
#ixl8pp {
white-space : pre-wrap; 
} 
#imt1yo {
white-space : pre-wrap; 
} 
#ifmslq {
white-space : pre-wrap; 
} 
#ivhlb9 {
white-space : pre-wrap; 
} 
#i9dpwb {
white-space : pre-wrap; 
} 
#i4m36h {
white-space : pre-wrap; 
} 
#ie7qpf {
white-space : pre-wrap; 
} 
#iqjtw2 {
white-space : pre-wrap; 
} 
#iw6czl {
white-space : pre-wrap; 
} 
#i1e06h {
white-space : pre-wrap; 
} 
#ilwelj {
white-space : pre-wrap; 
} 
#igx2uu {
white-space : pre-wrap; 
} 
#i5f6pk {
white-space : pre-wrap; 
} 
#ig2v8y {
white-space : pre-wrap; 
} 
#izykce {
white-space : pre-wrap; 
} 
#iqlu2n {
white-space : pre-wrap; 
} 
#igll1i {
padding : 10px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
} 
#i80ug5 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iihyd1 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ia5c5o {
padding : 10px; 
min-height : 100px; 
width : 60%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
:root {
--hamburgerAnimatedZIndex :  0; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 0; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
#menu__toggle {
opacity : 0; 
position : absolute; 
height : 40px; 
width : 40px; 
z-index : 2; 
cursor : pointer; 
} 
#menu__toggle:checked  {
left : 30px; 
} 
#menu__toggle:checked ~ .menu__btn {
left : 30px; 
} 
#menu__toggle:checked + .menu__btn > .span_middle {
transform : rotate(45deg); 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black !important; 
} 
#menu__toggle:checked + .menu__btn > .span_before {
top : 5px; 
transform : rotate(45deg); 
} 
#menu__toggle:checked + .menu__btn > .span_after {
top : 0px; 
transform : rotate(135deg); 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black !important; 
} 
#menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.menu__btn {
position : absolute; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 25px; 
height : 2px; 
z-index : 1; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.linkhrefs {
position : fixed; 
} 
#ileqxj {
padding : 10px; 
display : block; 
} 
#i4d4go {
color : rgb(214, 164, 25); 
font-family : Inika; 
font-size : 20px; 
text-align : center; 
background-color : rgb(41, 42, 46); 
} 
#i6dzmi {
color : black; 
height : 125px; 
} 
#im1e0j {
padding : 10px; 
display : block; 
} 
#ixxam4 {
color : rgb(255, 255, 255); 
font-family : Inika; 
font-size : 20px; 
text-align : center; 
background-color : rgb(41, 42, 46); 
} 
@media only screen and (max-width: 480px) {#ileqxj {
display : none; 
}} 
@media only screen and (max-width: 480px) {#im1e0j {
display : none; 
}} 
.link {
color : #fff; 
} 
#i08uu7 {
padding : 10px 5px 10px 5px; 
display : block; 
color : #fff; 
} 
#i0ql2c {
padding : 10px; 
display : block; 
} 
#if5ki3 {
padding : 10px; 
display : block; 
} 

  
  
}
  