
#id62fa735bfc8b2a0012c15bbc {
  .gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
} 
#init {
padding : 10px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
.navbar-items-c {
display : inline-block; 
float : right; 
} 
.navbar {
color : rgb(221, 221, 221); 
min-height : 50px; 
width : 100%; 
} 
.navbar-container {
max-width : 950px; 
margin-top : 0px; 
margin-right : auto; 
margin-bottom : 0px; 
margin-left : auto; 
width : 95%; 
} 
.navbar-container::after  {
content : ""; 
clear : both; 
display : block; 
} 
.navbar-brand {
vertical-align : top; 
display : inline-block; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
min-height : 50px; 
min-width : 50px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.navbar-menu {
padding-top : 10px; 
padding-right : 0px; 
padding-bottom : 10px; 
padding-left : 0px; 
display : block; 
float : right; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
} 
.navbar-menu-link {
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
color : inherit; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
padding-top : 10px; 
padding-right : 15px; 
padding-bottom : 10px; 
padding-left : 15px; 
} 
.navbar-burger {
margin-top : 10px; 
margin-right : 0px; 
margin-bottom : 10px; 
margin-left : 0px; 
width : 45px; 
padding-top : 5px; 
padding-right : 10px; 
padding-bottom : 5px; 
padding-left : 10px; 
display : none; 
float : right; 
cursor : pointer; 
} 
.navbar-burger-line {
padding-top : 1px; 
padding-right : 1px; 
padding-bottom : 1px; 
padding-left : 1px; 
background-color : white; 
margin-top : 5px; 
margin-right : 0px; 
margin-bottom : 5px; 
margin-left : 0px; 
} 
#iiwh {
padding : 10px; 
min-height : 100px; 
__bg-type : color; 
background-image : linear-gradient(#E5F0FF,#E5F0FF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
height : 224.391px; 
position : relative; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#iwhfk {
display : flex; 
position : relative; 
height : 145.4px; 
} 
#iapoz {
padding : 10px; 
display : block; 
float : left; 
font-size : 18px; 
position : absolute; 
right : 175px; 
left : auto; 
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
font-weight : 700; 
} 
#irnm4 {
padding : 10px; 
display : block; 
position : absolute; 
top : 45px; 
float : right; 
right : 1px; 
font-family : Times New Roman, Times, serif; 
font-size : 38px; 
color : #56575C; 
opacity : 0.67; 
} 
#i4l02 {
position : relative; 
top : 25px; 
} 
#i39pw {
padding : 10px; 
display : block; 
font-size : 14px; 
font-family : Times New Roman, Times, serif; 
} 
#about {
padding : 10px; 
min-height : 100px; 
height : 1100px; 
__bg-type : color; 
background-image : linear-gradient(#56575C,#56575C); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
position : static; 
font-size : 20px; 
display : block; 
} 
#i8bw3 {
padding : 10px; 
min-height : 100px; 
height : 365px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#team {
padding : 10px; 
min-height : 100px; 
height : 607px; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5lhisl3.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
opacity : 1; 
width : 100%; 
} 
#ig01q {
padding : 10px; 
min-height : 100px; 
height : 839px; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5lhi25d.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
opacity : 1; 
} 
#i9k4z {
height : 811px; 
} 
#i992y {
position : relative; 
height : 787px; 
} 
#is7b4 {
height : 350px; 
width : 75%; 
position : absolute; 
display : block; 
top : 25%; 
right : 12%; 
left : 10%; 
bottom : 0; 
} 
#ir2t8 {
position : relative; 
display : flex; 
height : 787.6px; 
} 
#ivtko {
padding : 10px; 
position : absolute; 
width : 100%; 
top : 225px; 
} 
#io9ap {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-size : 32px; 
text-align : center; 
} 
#imuqh {
white-space : pre-wrap; 
} 
#ipaf7 {
padding : 10px; 
min-height : 100px; 
} 
#i4yxi {
padding : 0 0 0 0; 
min-height : 0; 
position : relative; 
border : solid #D6A419; 
width : 100%; 
height : 0; 
display : block; 
} 
#iyf67 {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 317px; 
position : relative; 
} 
#id3u8 {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
#ie17j {
padding : 10px; 
display : block; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
top : 32%; 
right : 0; 
left : 0; 
width : 100%; 
text-align : center; 
} 
#iwl9yl {
padding : 10px; 
display : block; 
position : absolute; 
width : 250px; 
font-size : 13px; 
font-family : Times New Roman, Times, serif; 
text-align : center; 
top : 47%; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
} 
#i97qdp {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 317px; 
} 
#i000fi {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 317px; 
} 
#ipxuf7 {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
#idhw1w {
padding : 10px; 
display : block; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
top : 32%; 
right : 0; 
left : 0; 
text-align : center; 
width : 100%; 
} 
#i7dq9v {
padding : 10px; 
display : block; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
font-size : 13px; 
text-align : center; 
width : 250px; 
top : 47%; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
bottom : 88.5px; 
} 
#iojvny {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
#ismbik {
padding : 10px; 
display : block; 
position : absolute; 
top : 32%; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
right : 0; 
left : 0; 
text-align : center; 
width : 100%; 
} 
#i96hmo {
padding : 10px; 
display : block; 
position : absolute; 
top : 47%; 
text-align : center; 
font-size : 13px; 
font-family : Times New Roman, Times, serif; 
width : 250px; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
} 
#itclbu {
width : 50%; 
position : relative; 
float : none; 
} 
#ihw0ie {
position : relative; 
width : 50%; 
} 
#iam2ci {
height : 487px; 
width : 100%; 
} 
#ingz1b {
padding : 10px; 
min-height : 100px; 
width : 647px; 
height : 428px; 
float : right; 
__bg-type : color; 
background-image : linear-gradient(#56575C,#56575C); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
position : relative; 
margin : 25px 10px 0 0; 
} 
#inp4q1 {
position : relative; 
margin : 15% 0 0 0; 
height : 144px; 
} 
#ihzsor {
color : black; 
width : 100px; 
height : 100px; 
float : right; 
margin : 0 10px 0 0; 
position : absolute; 
right : 0; 
} 
#ixyrcl {
border : none rgb(33, 37, 41); 
border-radius : 0 0 0 0; 
} 
#ijaal3 {
padding : 10px; 
min-height : 100px; 
} 
#iw9tdw {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
} 
#iie9mm {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#iwoxtl {
padding : 10px; 
min-height : 100px; 
float : none; 
position : relative; 
} 
#i0j0u4 {
padding : 10px; 
min-height : 0; 
position : absolute; 
height : 79px; 
width : 378px; 
top : 5px; 
left : 125px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
} 
#itmjq2 {
padding : 10px; 
min-height : 0; 
width : 145px; 
height : 56px; 
top : 0; 
position : relative; 
} 
#iorngw {
color : black; 
width : 42px; 
float : right; 
top : 1px; 
} 
#ij4jng {
padding : 10px; 
min-height : 0; 
width : 347px; 
height : 55px; 
position : relative; 
} 
#i2vhuk {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
color : #D6A419; 
font-size : 24px; 
top : 0; 
position : absolute; 
} 
#ie1g45 {
white-space : pre-wrap; 
} 
#io4wwo {
color : black; 
width : 100px; 
height : 100px; 
float : right; 
margin : 0 10px 0 0; 
position : absolute; 
right : 0; 
} 
#iwvz3g {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
} 
#ifenji {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#isd8oi {
padding : 10px; 
min-height : 100px; 
} 
#ij3lyh {
border : none rgb(33, 37, 41); 
border-radius : 0 0 0 0; 
} 
#id8tik {
position : relative; 
margin : 15% 0 0 0; 
height : 144px; 
} 
#ibjisg {
color : black; 
width : 42px; 
float : right; 
top : 1px; 
} 
#iqmdlg {
padding : 10px; 
min-height : 0; 
width : 145px; 
height : 56px; 
top : 0; 
position : relative; 
} 
#i30szm {
white-space : pre-wrap; 
} 
#if11mj {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
color : #D6A419; 
font-size : 24px; 
top : 0; 
position : absolute; 
} 
#iraiys {
padding : 10px; 
min-height : 0; 
width : 347px; 
height : 55px; 
position : relative; 
} 
#ib4qm6 {
padding : 10px; 
min-height : 0; 
position : absolute; 
height : 79px; 
width : 378px; 
top : 5px; 
left : 125px; 
__bg-type : color; 
background-image : linear-gradient(#292A2E,#292A2E); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
display : flex; 
} 
#isf544 {
padding : 10px; 
min-height : 100px; 
float : none; 
position : relative; 
} 
#inq8p6 {
padding : 10px; 
min-height : 100px; 
width : 647px; 
height : 428px; 
float : left; 
__bg-type : color; 
background-image : linear-gradient(#56575C,#56575C); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
position : relative; 
margin : 25px 0 0 10px; 
} 
#iqnw8z {
white-space : pre-wrap; 
} 
#i6zl4l {
white-space : pre-wrap; 
} 
#ilr8fp {
padding : 10px; 
min-height : 100px; 
height : 417px; 
__bg-type : color; 
background-image : linear-gradient(#E5F0FF,#E5F0FF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
position : relative; 
} 
#ipiytl {
padding : 10px; 
min-height : 0; 
display : flex; 
position : relative; 
height : 85px; 
border : none rgb(33, 37, 41); 
width : 100%; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
font-weight : 400; 
} 
#ipxxa2 {
padding : 10px; 
min-height : 0; 
width : 50%; 
height : 69px; 
} 
#iqu2rt {
padding : 10px; 
min-height : 0; 
width : 50%; 
position : relative; 
height : 69px; 
} 
#ibcvzj {
padding : 10px; 
display : block; 
float : right; 
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
text-decoration : none; 
font-weight : 700; 
} 
#iyabfu {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
font-weight : 700; 
} 
#i8z5yi {
height : 125.2px; 
display : flex; 
float : right; 
text-align : left; 
position : relative; 
} 
#i3990z {
padding : 10px; 
min-height : 100px; 
display : none; 
} 
#i123kk {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 266px; 
} 
#ijhnci {
height : 100%; 
position : absolute; 
top : 0; 
left : 0; 
width : 100%; 
} 
#ic68xh {
padding : 10px; 
min-height : 0; 
display : flex; 
position : relative; 
width : 100%; 
height : 450px; 
} 
#iru0nz {
padding : 10px; 
min-height : 100px; 
position : absolute; 
width : 50%; 
height : 100%; 
top : 0; 
left : 0; 
__bg-type : color; 
background-image : linear-gradient(#D6A419,#D6A419); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ik4y1o {
padding : 10px; 
min-height : 0; 
position : relative; 
height : 88px; 
width : 100%; 
display : flex; 
} 
#ios5on {
padding : 10px; 
height : 100%; 
position : relative; 
width : 50%; 
} 
#iho3im {
padding : 10px; 
min-height : 0; 
height : 100%; 
width : 50%; 
} 
#ixdbtg {
padding : 10px; 
display : block; 
text-align : right; 
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
top : 0; 
position : relative; 
right : 0; 
} 
#iqu8rd {
padding : 10px; 
display : block; 
color : #000000; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
} 
#ipo1c9 {
padding : 10px; 
display : block; 
text-align : right; 
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
top : 0; 
position : relative; 
right : 0; 
} 
#iy8gl1 {
padding : 10px; 
height : 100%; 
position : relative; 
width : 50%; 
} 
#irewm9 {
padding : 10px; 
display : block; 
color : #000000; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
} 
#ivvhgh {
padding : 10px; 
min-height : 0; 
height : 100%; 
width : 50%; 
} 
#ieajah {
padding : 10px; 
min-height : 0; 
position : relative; 
height : 90px; 
width : 100%; 
display : flex; 
} 
#ic3h3i {
padding : 10px; 
min-height : 100px; 
height : 310px; 
position : relative; 
} 
#ie2nuf {
padding : 10px; 
display : block; 
text-align : center; 
line-height : 35px; 
font-family : Times New Roman, Times, serif; 
font-size : 20px; 
} 
#ii7lc7 {
white-space : pre-wrap; 
} 
#ifxwi5 {
white-space : pre-wrap; 
} 
#i9kjvc {
white-space : pre-wrap; 
} 
#i3oxbc {
white-space : pre-wrap; 
} 
#ips5c9 {
padding : 10px; 
min-height : 100px; 
position : relative; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
.hc-state {
border-radius : 100px 100px 100px 100px; 
width : 227px; 
height : 67px; 
min-height : 0; 
position : absolute; 
top : 10px; 
right : 35%; 
__bg-type : color; 
background-image : linear-gradient(#D6A419,#D6A419); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ic4k0a {
padding : 10px; 
min-height : 0; 
position : relative; 
display : flex; 
width : 100%; 
height : 162px; 
} 
#i0exw3 {
padding : 10px; 
min-height : 100px; 
height : 146px; 
} 
#idapj2 {
padding : 10px; 
height : 100%; 
width : 50%; 
} 
#ipog2k {
padding : 10px; 
min-height : 0; 
width : 50%; 
height : 100%; 
display : flex; 
position : relative; 
} 
#inj60i {
color : black; 
height : 100%; 
width : 15%; 
float : right; 
} 
#iajh9o {
padding : 10px; 
display : block; 
color : #D1AF7C; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
margin : 0 0 0 10px; 
} 
#ip30xz {
padding : 10px; 
display : block; 
color : #FFFFFF; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
} 
#ivovhk {
white-space : pre-wrap; 
} 
#i1gx9 {
display : flex; 
} 
#i959v7 {
padding : 0 0 0 0; 
min-height : 100px; 
border : solid #56575C; 
} 
#isqixk {
padding : 0 0 0 0; 
float : right; 
position : absolute; 
right : 0; 
height : 100%; 
width : 0; 
border : solid #D6A419; 
} 
#i3kjf6 {
display : flex; 
position : relative; 
} 
#ivdyzy {
padding : 0 0 0 0; 
min-height : 0; 
position : absolute; 
height : 100%; 
border : 2px solid #D6A419; 
top : 0; 
right : 0; 
} 
#il59zj {
padding : 0 0 0 0; 
min-height : 100px; 
border : 1px solid #D1AF7C; 
} 
#i8il5j {
padding : 10px; 
min-height : 0; 
width : 100%; 
height : 62px; 
display : flex; 
} 
#ir0y3r {
padding : 5px 10px 10px 10px; 
min-height : 0; 
width : 50%; 
height : 100%; 
font-size : 19px; 
} 
#ixsm5i {
padding : 5px 10px 10px 10px; 
min-height : 0; 
width : 50%; 
height : 100%; 
} 
#i930wg {
padding : 0 0 0 10px; 
display : block; 
text-align : right; 
top : 0; 
font-size : 24px; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
} 
#iim50b {
padding : 0 10px 0 0; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
} 
@media only screen and (max-width: 480px) {#irnm4 {
font-size : 24px; 
right : 25px; 
}} 
@media only screen and (max-width: 480px) {#i4l02 {
display : block; 
}} 
@media only screen and (max-width: 480px) {#iwhfk {
width : 100%; 
display : block; 
height : 139.8px; 
}} 
@media only screen and (max-width: 480px) {#iapoz {
width : 150px; 
right : 50px; 
}} 
@media only screen and (max-width: 480px) {#i959v7 {
display : block; 
min-height : 0; 
width : 100%; 
height : 0; 
}} 
@media only screen and (max-width: 480px) {#iiwh {
height : 576.391px; 
}} 
@media only screen and (max-width: 480px) {#i1gx9 {
width : 100%; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#about {
height : 2261px; 
}} 
@media only screen and (max-width: 480px) {#iam2ci {
display : block; 
position : relative; 
height : 636px; 
}} 
@media only screen and (max-width: 480px) {#ingz1b {
width : 100%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#itclbu {
width : 100%; 
height : 296.4px; 
}} 
@media only screen and (max-width: 480px) {#ihw0ie {
width : 100%; 
height : 269.8px; 
top : 0; 
}} 
@media only screen and (max-width: 480px) {#inp4q1 {
width : 100%; 
height : 148px; 
}} 
@media only screen and (max-width: 480px) {#i3kjf6 {
width : 50%; 
}} 
@media only screen and (max-width: 480px) {#ixyrcl {
width : 50%; 
}} 
@media only screen and (max-width: 480px) {#iwoxtl {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0j0u4 {
left : 10%; 
right : 0; 
width : 80%; 
padding : 0 0 0 0; 
height : 57px; 
}} 
@media only screen and (max-width: 480px) {#i2vhuk {
font-size : 14px; 
left : 0; 
top : 5px; 
}} 
@media only screen and (max-width: 480px) {#inq8p6 {
width : 100%; 
margin : 10px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#team {
height : 773px; 
background-position : center center; 
background-size : cover; 
opacity : 1; 
__ : 1; 
}} 
@media only screen and (max-width: 480px) {#i0exw3 {
height : 184px; 
top : 0; 
bottom : 100px; 
min-height : 0; 
}} 
@media only screen and (max-width: 480px) {#ilr8fp {
height : 1360px; 
}} 
@media only screen and (max-width: 480px) {#ir2t8 {
display : none; 
}} 
.gjs-lofty-navbar-center-topnav {
position : relative; 
overflow-x : hidden; 
overflow-y : hidden; 
background-color : rgb(41, 42, 46); 
height : 150px; 
} 
.gjs-lofty-navbar-center-topnav p {
float : left; 
text-align : center; 
padding-top : 14px; 
padding-right : 16px; 
padding-bottom : 14px; 
padding-left : 16px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.gjs-lofty-navbar-center-topnav p.left {
color : white; 
} 
.gjs-lofty-navbar-center-topnav-centered-left {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-centered-right {
float : none; 
position : absolute; 
top : 45%; 
transform : translate(-50%, -50%); 
font-family : Inika; 
font-size : 20px; 
} 
.gjs-lofty-navbar-center-topnav-right {
float : right; 
position : relative; 
top : 50%; 
font-size : 17px; 
} 
.gjs-lofty-navbar-center-topnav-image {
position : absolute; 
top : 10%; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span {
transform : rotate(45deg); 
left : 120px; 
top : -30px; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::before {
top : 0px; 
transform : rotate(0deg); 
background-color : black; 
} 
#gjs-lofty-navbar-center-menu__toggle:checked + .menu__btn > span::after {
top : 0px; 
background-color : black; 
transform : rotate(90deg); 
} 
#gjs-lofty-navbar-center-menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.gjs-lofty-navbar-center-menu__btn {
position : absolute; 
top : 45%; 
left : 5%; 
width : 26px; 
height : 26px; 
cursor : pointer; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-menu__btn > span, .gjs-lofty-navbar-center-menu__btn > span::before, .gjs-lofty-navbar-center-menu__btn > span::after {
display : block; 
position : absolute; 
width : 100%; 
height : 2px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__btn > span::before {
content : ""; 
top : -8px; 
} 
.gjs-lofty-navbar-center-menu__btn > span::after {
content : ""; 
top : 8px; 
} 
.gjs-lofty-navbar-center-menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100%; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.gjs-lofty-navbar-center-menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.gjs-lofty-navbar-center-dropbtn {
background-color : rgb(41, 42, 46); 
color : white; 
padding-top : 12px; 
padding-right : 12px; 
padding-bottom : 12px; 
padding-left : 12px; 
font-size : 14px; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-bottom-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-bottom-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
cursor : pointer; 
max-width : 100%; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown {
position : relative; 
top : 20%; 
} 
.gjs-lofty-navbar-center-dropdown-content {
display : none; 
position : absolute; 
background-color : gray; 
min-width : 60px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 1; 
} 
.gjs-lofty-navbar-center-dropdown-content a {
color : white; 
padding-top : 4px; 
padding-right : 6px; 
padding-bottom : 4px; 
padding-left : 6px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : block; 
font-family : Arial, Helvetica, sans-serif; 
} 
.gjs-lofty-navbar-center-dropdown-content a:hover {
background-color : rgb(241, 241, 241); 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropdown-content {
display : block; 
} 
.gjs-lofty-navbar-center-dropdown:hover .dropbtn {
background-color : rgb(41, 42, 46); 
} 
@media only screen and (max-width: 480px) {#i992y {
width : 100%; 
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#ivtko {
width : 100%; 
top : 35px; 
}} 
@media only screen and (max-width: 480px) {#io9ap {
width : 100%; 
font-size : 24px; 
}} 
@media only screen and (max-width: 480px) {#ipaf7 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.gjs-container.container {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ig01q {
height : 567px; 
background-repeat : no-repeat; 
background-size : cover; 
background-attachment : scroll; 
background-position : center center; 
}} 
@media only screen and (max-width: 480px) {#iru0nz {
width : 100%; 
height : 30%; 
position : absolute; 
min-height : 0; 
}} 
@media only screen and (max-width: 480px) {#ic68xh {
display : block; 
height : 752px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i123kk {
height : 142px; 
}} 
@media only screen and (max-width: 480px) {#ic3h3i {
min-height : 0; 
height : 100%; 
}} 
#i3fvqj {
padding : 10px; 
display : none; 
font-family : Times New Roman, Times, serif; 
font-size : 24px; 
text-align : center; 
color : #D6A419; 
} 
#contact {
padding : 10px; 
min-height : 100px; 
position : absolute; 
display : block; 
float : none; 
width : 50%; 
height : 100%; 
top : 0; 
right : 0; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5lhi25d.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
@media only screen and (max-width: 480px) {#contact {
height : 70%; 
position : absolute; 
width : 100%; 
min-height : 0; 
top : 30%; 
}} 
@media only screen and (max-width: 480px) {#ie2nuf {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i9k4z {
height : 100%; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ib4qm6 {
left : 10%; 
right : 0; 
width : 80%; 
padding : 0 0 0 0; 
height : 58px; 
}} 
@media only screen and (max-width: 480px) {#id8tik {
min-height : 0; 
height : 151px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i8z5yi {
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#isf544 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#if11mj {
font-size : 14px; 
left : 0; 
top : 5px; 
}} 
@media only screen and (max-width: 480px) {#ifenji {
padding : 10px 5px 10px 5px; 
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#iie9mm {
font-size : 16px; 
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 480px) {#iwvz3g {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 480px) {#iw9tdw {
padding : 10px 5px 10px 5px; 
}} 
@media only screen and (max-width: 480px) {#ipxuf7 {
right : 0; 
left : 12%; 
top : 2%; 
}} 
@media only screen and (max-width: 480px) {#idhw1w {
right : 0; 
left : 0; 
font-size : 18px; 
top : 24%; 
}} 
@media only screen and (max-width: 480px) {#i7dq9v {
right : 0; 
left : 0; 
width : 100%; 
top : 32%; 
}} 
@media only screen and (max-width: 480px) {#iyf67 {
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#id3u8 {
right : 0; 
left : 12%; 
top : 2%; 
bottom : 0; 
}} 
@media only screen and (max-width: 480px) {#ie17j {
right : 0; 
left : 0; 
width : 100%; 
top : 20%; 
}} 
@media only screen and (max-width: 480px) {#iwl9yl {
width : 100%; 
right : 0; 
left : 0; 
top : 30%; 
}} 
@media only screen and (max-width: 480px) {#iojvny {
right : 0; 
left : 12%; 
}} 
@media only screen and (max-width: 480px) {#ismbik {
right : 0; 
left : 0; 
width : 100%; 
top : 40%; 
}} 
@media only screen and (max-width: 480px) {#i96hmo {
width : 100%; 
right : 0; 
left : 0; 
top : 60%; 
}} 
@media only screen and (max-width: 480px) {#inj60i {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iajh9o {
font-size : 20px; 
padding : 0 10px 10px 10px; 
}} 
@media only screen and (max-width: 480px) {#i8bw3 {
height : 421px; 
}} 
@media only screen and (max-width: 992px) {#i9k4z {
display : block; 
height : 675px; 
}} 
@media only screen and (max-width: 992px) {#ir2t8 {
width : 100%; 
height : 400px; 
}} 
@media only screen and (max-width: 992px) {#is7b4 {
top : 5%; 
right : 0; 
bottom : 0; 
left : 12%; 
}} 
@media only screen and (max-width: 992px) {#i992y {
width : 100%; 
height : 250px; 
}} 
@media only screen and (max-width: 992px) {#ivtko {
top : 0; 
}} 
@media only screen and (max-width: 992px) {#ig01q {
height : 696px; 
}} 
@media only screen and (max-width: 992px) {#iiwh {
height : 338.39099999999996px; 
}} 
@media only screen and (max-width: 992px) {#iwhfk {
height : 264.8px; 
}} 
@media only screen and (max-width: 992px) {#iapoz {
top : 20%; 
}} 
@media only screen and (max-width: 992px) {#irnm4 {
top : 35%; 
}} 
@media only screen and (max-width: 992px) {#inp4q1 {
margin : 10px 0 0 0; 
}} 
@media only screen and (max-width: 992px) {#ingz1b {
width : 90%; 
height : 268px; 
margin : 25px 10px 0 0; 
padding : 0 10px 10px 10px; 
min-height : 0; 
}} 
@media only screen and (max-width: 992px) {#team {
width : 100%; 
position : relative; 
height : 439px; 
}} 
@media only screen and (max-width: 992px) {#itclbu {
width : 50%; 
float : right; 
height : 325px; 
min-height : 0; 
}} 
@media only screen and (max-width: 992px) {#iam2ci {
width : 100%; 
height : 352px; 
}} 
@media only screen and (max-width: 992px) {#i0j0u4 {
left : 0; 
right : 0; 
width : 100%; 
top : 10%; 
}} 
@media only screen and (max-width: 992px) {#ihzsor {
width : 125px; 
height : 125px; 
}} 
@media only screen and (max-width: 992px) {#iw9tdw {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iie9mm {
font-size : 14px; 
line-height : 14px; 
}} 
@media only screen and (max-width: 992px) {#i2vhuk {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#iorngw {
width : 35px; 
height : 35px; 
}} 
@media only screen and (max-width: 992px) {#ihw0ie {
width : 50%; 
min-height : 0; 
height : 325px; 
}} 
@media only screen and (max-width: 992px) {#inq8p6 {
width : 90%; 
min-height : 0; 
height : 268px; 
}} 
@media only screen and (max-width: 992px) {#id8tik {
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 992px) {#ib4qm6 {
left : 0; 
right : 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#if11mj {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ibjisg {
width : 35px; 
height : 35px; 
}} 
@media only screen and (max-width: 992px) {#iwvz3g {
font-size : 16px; 
line-height : 14px; 
}} 
@media only screen and (max-width: 992px) {#ifenji {
font-size : 14px; 
line-height : 14px; 
}} 
@media only screen and (max-width: 992px) {#io4wwo {
width : 125px; 
height : 125px; 
}} 
@media only screen and (max-width: 992px) {#inj60i {
width : 125px; 
}} 
@media only screen and (max-width: 992px) {#id3u8 {
left : 30%; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#ie17j {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#iwl9yl {
width : 100%; 
left : 0; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#iyf67 {
height : 328px; 
}} 
@media only screen and (max-width: 992px) {#iojvny {
left : 30%; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#ismbik {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#i96hmo {
width : 100%; 
left : 0; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#ipxuf7 {
left : 30%; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#idhw1w {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
@media only screen and (max-width: 992px) {#i7dq9v {
width : 100%; 
left : 0; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#contact {
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5lhlfk7.jpg'); 
__bg-type : img; 
}} 
#i4bd9p {
padding : 0 0 0 0; 
min-height : 100px; 
position : relative; 
width : 48%; 
box-shadow : -1px 1px 5px 0.25px black; 
margin : 0 2% 0 0; 
} 
#ipl52i {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 70%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5ju8gxu.png'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#i4b4t4 {
padding : 0 0 0 0; 
min-height : 0; 
position : relative; 
height : 30%; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#ionvnj {
padding : 5px 0 10px 10px; 
min-height : 0; 
position : absolute; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 40px; 
height : 40px; 
border-radius : 25px 25px 25px 25px; 
top : -20%; 
right : 5%; 
box-shadow : 1px 1px 5px 1px black; 
} 
#irtck9 {
padding : 10px 10px 5px 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
font-size : 18px; 
text-align : center; 
position : relative; 
} 
#iveut6 {
padding : 0 0 0 0; 
min-height : 0; 
width : 75%; 
height : 0; 
position : relative; 
left : 15%; 
border : 1px solid #D6A419; 
} 
#i10f5f {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 70%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5jwhc4p.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#idvnf7 {
padding : 10px 10px 5px 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
font-size : 18px; 
text-align : center; 
position : relative; 
} 
#iynw1a {
padding : 5px 0 10px 10px; 
min-height : 0; 
position : absolute; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 40px; 
height : 40px; 
border-radius : 25px 25px 25px 25px; 
top : -20%; 
right : 5%; 
box-shadow : 1px 1px 5px 1px black; 
} 
#iobiu6 {
padding : 0 0 0 0; 
min-height : 0; 
width : 75%; 
height : 0; 
position : relative; 
left : 15%; 
border : 1px solid #D6A419; 
} 
#ir1615 {
padding : 0 0 0 0; 
min-height : 0; 
position : relative; 
height : 30%; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#i12igg {
padding : 0 0 0 0; 
min-height : 100px; 
position : relative; 
width : 48%; 
box-shadow : -1px 1px 5px 0.25px black; 
margin : 0 2% 0 0; 
} 
#i456ma {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 70%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5k1x7og.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#i00ijp {
padding : 10px 10px 5px 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
font-size : 18px; 
text-align : center; 
position : relative; 
} 
#inoj78 {
padding : 5px 0 10px 10px; 
min-height : 0; 
position : absolute; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 40px; 
height : 40px; 
border-radius : 25px 25px 25px 25px; 
top : -20%; 
right : 5%; 
box-shadow : 1px 1px 5px 1px black; 
} 
#izcoaj {
padding : 0 0 0 0; 
min-height : 0; 
width : 75%; 
height : 0; 
position : relative; 
left : 15%; 
border : 1px solid #D6A419; 
} 
#icwa41 {
padding : 0 0 0 0; 
min-height : 0; 
position : relative; 
height : 30%; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#idkulj {
padding : 0 0 0 0; 
min-height : 100px; 
position : relative; 
width : 48%; 
box-shadow : -1px 1px 5px 0.25px black; 
margin : 0 2% 0 0; 
} 
#iyaah8 {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 70%; 
__bg-type : img; 
background-image : url('https://assetsprojects.s3.amazonaws.com/406450il5k1h58p.jpg'); 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
} 
#igmw4t {
padding : 10px 10px 5px 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
font-size : 18px; 
text-align : center; 
position : relative; 
} 
#ille9s {
padding : 5px 0 10px 10px; 
min-height : 0; 
position : absolute; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 40px; 
height : 40px; 
border-radius : 25px 25px 25px 25px; 
top : -20%; 
right : 5%; 
box-shadow : 1px 1px 5px 1px black; 
} 
#ig1ymb {
padding : 0 0 0 0; 
min-height : 0; 
width : 75%; 
height : 0; 
position : relative; 
left : 15%; 
border : 1px solid #D6A419; 
} 
#i93oc6 {
padding : 0 0 0 0; 
min-height : 0; 
position : relative; 
height : 30%; 
width : 100%; 
__bg-type : color; 
background-image : linear-gradient(#FFFFFF,#FFFFFF); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
} 
#it97iq {
padding : 0 0 0 0; 
min-height : 100px; 
position : relative; 
width : 48%; 
box-shadow : -1px 1px 5px 0.25px black; 
margin : 0 2% 0 0; 
} 
@media only screen and (max-width: 992px) {#ilr8fp {
height : 660px; 
}} 
#i1vxgl {
position : relative; 
width : 100%; 
height : 306px; 
} 
#if2sr8 {
width : 50%; 
position : relative; 
padding : 10px 10px 10px 10px; 
height : 100%; 
display : flex; 
} 
#i8w3cx {
width : 50%; 
position : relative; 
height : 100%; 
display : flex; 
margin : 0 0 0; 
padding : 10px 10px 10px 10px; 
} 
@media only screen and (max-width: 992px) {#i1vxgl {
margin : 10px 0 0 0; 
display : block; 
width : 100%; 
height : 524px; 
}} 
@media only screen and (max-width: 992px) {#if2sr8 {
width : 100%; 
height : 50%; 
}} 
@media only screen and (max-width: 992px) {#i8w3cx {
width : 100%; 
height : 50%; 
}} 
@media only screen and (max-width: 480px) {#i1vxgl {
width : 100%; 
height : 1242px; 
}} 
@media only screen and (max-width: 480px) {#if2sr8 {
width : 100%; 
display : block; 
height : 50%; 
}} 
@media only screen and (max-width: 480px) {#i4bd9p {
width : 100%; 
height : 48%; 
margin : 0 0 5% 0; 
top : 0; 
}} 
@media only screen and (max-width: 480px) {#i12igg {
width : 100%; 
height : 48%; 
min-height : 0; 
}} 
@media only screen and (max-width: 480px) {#i8w3cx {
display : block; 
height : 49.53%; 
}} 
@media only screen and (max-width: 480px) {#it97iq {
width : 100%; 
height : 48%; 
top : 0; 
bottom : 0; 
margin : 0 0 2% 0; 
}} 
@media only screen and (max-width: 480px) {#idkulj {
width : 100%; 
height : 48%; 
top : 2%; 
bottom : 2%; 
margin : 0 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#ij4jng {
font-size : medium; 
}} 
@media only screen and (max-width: 480px) {#iqmdlg {
display : block; 
}} 
#ixzslc {
position : relative; 
width : 33%; 
} 
#iafvpq {
width : 33%; 
} 
#i83dhi {
width : 33%; 
} 
#ikswhd {
width : 33%; 
} 
#iky5hi {
width : 33%; 
} 
#ih1xin {
width : 33%; 
} 
#ivbdjv {
width : 33%; 
} 
#ifn8qn {
width : 33%; 
} 
#ijybbx {
width : 33%; 
} 
#ittzfj {
position : relative; 
width : 100%; 
} 
#i6xc2n {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#i6xc2n {
left : 12%; 
}} 
@media only screen and (max-width: 992px) {#i6xc2n {
right : 0; 
left : 30%; 
}} 
#ieaqui {
padding : 10px; 
display : block; 
color :  #D6A419; 
position : absolute; 
top : 32%; 
right : 0; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
left : 0; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#ieaqui {
left : 0; 
right : 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ieaqui {
left : 0; 
right : 0; 
width : 100%; 
}} 
#isaitc {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-family : Times New Roman, Times, serif; 
font-size : 13px; 
position : absolute; 
top : 47%; 
border : none rgb(255, 255, 255); 
width : 250px; 
right : 0; 
left : 29%; 
} 
@media only screen and (max-width: 480px) {#isaitc {
width : 100%; 
left : 0; 
right : 0; 
padding : 10px 10px 10px 10px; 
margin : 0 0 0 0; 
bottom : 48.5px; 
}} 
@media only screen and (max-width: 992px) {#isaitc {
width : 100%; 
left : 0; 
right : 0; 
}} 
#ifh71k {
padding : 10px; 
min-height : 100px; 
width : 100%; 
display : flex; 
position : relative; 
float : none; 
text-align : center; 
height : 317px; 
} 
#id6nnm {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#id6nnm {
left : 12%; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#id6nnm {
left : 30%; 
right : 0; 
}} 
#ihokcc {
padding : 10px; 
display : block; 
position : absolute; 
top : 32%; 
right : 0; 
color :  #D6A419; 
font-family : Times New Roman, Times, serif; 
left : 0; 
text-align : center; 
} 
@media only screen and (max-width: 480px) {#ihokcc {
left : 0; 
right : 0; 
font-size : 18px; 
padding : 10px 0 10px 0; 
top : 20%; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ihokcc {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
#i7b7xz {
padding : 10px; 
display : block; 
position : absolute; 
font-size : 13px; 
font-family : Times New Roman, Times, serif; 
text-align : center; 
top : 47%; 
width : 250px; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
} 
@media only screen and (max-width: 480px) {#i7b7xz {
left : 0; 
right : 0; 
width : 100%; 
top : 30%; 
}} 
@media only screen and (max-width: 992px) {#i7b7xz {
right : 0; 
left : 0; 
width : 100%; 
}} 
#iif82v {
padding : 10px; 
min-height : 100px; 
height : 317px; 
position : relative; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#iif82v {
height : 100%; 
}} 
@media only screen and (max-width: 992px) {#iif82v {
height : 328px; 
}} 
#itxqqt {
padding : 10px; 
display : block; 
position : absolute; 
width : 250px; 
font-family : Times New Roman, Times, serif; 
font-size : 13px; 
text-align : center; 
top : 47%; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
bottom : 43.0156px; 
} 
@media only screen and (max-width: 480px) {#itxqqt {
right : 0; 
left : 0; 
width : 100%; 
top : 32%; 
}} 
@media only screen and (max-width: 992px) {#itxqqt {
width : 100%; 
right : 0; 
left : 0; 
font-size : 13px; 
}} 
#i9gtav {
color : black; 
top : 2%; 
right : 0; 
position : absolute; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#i9gtav {
right : 0; 
left : 12%; 
top : 2%; 
}} 
@media only screen and (max-width: 992px) {#i9gtav {
right : 0; 
left : 30%; 
}} 
#i3vw3r {
white-space : pre-wrap; 
} 
#i2yiun {
padding : 10px; 
display : block; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
top : 32%; 
right : 0; 
float : none; 
left : 0; 
text-align : center; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#i2yiun {
right : 0; 
left : 0; 
width : 100%; 
padding : 10px 10px 10px 0; 
top : 24%; 
bottom : 0; 
height : 40px; 
}} 
@media only screen and (max-width: 992px) {#i2yiun {
width : 100%; 
left : 0; 
right : 0; 
text-align : center; 
}} 
#i1uoff {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
height : 317px; 
} 
@media only screen and (max-width: 480px) {#i1uoff {
height : 100%; 
}} 
@media only screen and (max-width: 992px) {#i1uoff {
height : 311px; 
}} 
#io7f8r {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#io7f8r {
left : 12%; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#io7f8r {
right : 0; 
left : 30%; 
}} 
#ig0tug {
padding : 10px; 
display : block; 
color :  #D6A419; 
position : absolute; 
top : 32%; 
right : 0; 
font-family : Times New Roman, Times, serif; 
left : 0; 
text-align : center; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#ig0tug {
right : 0; 
left : 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ig0tug {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
#ib6yhk {
padding : 10px; 
display : block; 
color : #FFFFFF; 
font-size : 13px; 
font-family : Times New Roman, Times, serif; 
position : absolute; 
width : 250px; 
top : 47%; 
right : 0; 
text-align : center; 
left : 29%; 
} 
@media only screen and (max-width: 480px) {#ib6yhk {
width : 100%; 
left : 0; 
right : 0; 
}} 
@media only screen and (max-width: 992px) {#ib6yhk {
width : 100%; 
left : 0; 
right : 0; 
}} 
#ibwuaq {
padding : 10px; 
min-height : 100px; 
height : 317px; 
position : relative; 
width : 100%; 
} 
#ioj89n {
color : black; 
position : absolute; 
top : 2%; 
right : 0; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#ioj89n {
left : 12%; 
right : 0; 
top : 2%; 
bottom : 0; 
}} 
@media only screen and (max-width: 992px) {#ioj89n {
left : 30%; 
right : 0; 
}} 
#iwjy4v {
padding : 10px; 
display : block; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
color :  #D6A419; 
top : 32%; 
right : 0; 
left : 0; 
text-align : center; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#iwjy4v {
left : 0; 
right : 0; 
width : 100%; 
top : 20%; 
}} 
@media only screen and (max-width: 992px) {#iwjy4v {
width : 100%; 
left : 0; 
right : 0; 
text-align : center; 
}} 
#ifgsi5 {
padding : 10px; 
display : block; 
font-size : 13px; 
text-align : center; 
position : absolute; 
font-family : Times New Roman, Times, serif; 
width : 250px; 
top : 47%; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
} 
@media only screen and (max-width: 480px) {#ifgsi5 {
left : 0; 
right : 0; 
width : 100%; 
top : 30%; 
}} 
@media only screen and (max-width: 992px) {#ifgsi5 {
width : 100%; 
left : 0; 
right : 0; 
}} 
#i8xmxg {
padding : 10px; 
min-height : 100px; 
height : 317px; 
width : 100%; 
position : relative; 
} 
@media only screen and (max-width: 480px) {#i8xmxg {
height : 100%; 
}} 
@media only screen and (max-width: 992px) {#i8xmxg {
height : 328px; 
}} 
#igb0i2 {
color : black; 
position : absolute; 
right : 0; 
top : 2%; 
left : 42%; 
} 
@media only screen and (max-width: 480px) {#igb0i2 {
right : 0; 
left : 12%; 
top : 2%; 
}} 
@media only screen and (max-width: 992px) {#igb0i2 {
left : 30%; 
right : 0; 
}} 
#iauejp {
padding : 10px; 
display : block; 
font-family : Times New Roman, Times, serif; 
position : absolute; 
top : 32%; 
right : 0; 
color : #D6A419; 
left : 0; 
text-align : center; 
width : 100%; 
} 
@media only screen and (max-width: 480px) {#iauejp {
right : 0; 
left : 0; 
width : 100%; 
top : 20%; 
}} 
@media only screen and (max-width: 992px) {#iauejp {
left : 0; 
right : 0; 
width : 100%; 
text-align : center; 
}} 
#i9c1xx {
padding : 10px; 
display : block; 
font-size : 13px; 
text-align : center; 
position : absolute; 
width : 250px; 
top : 47%; 
right : 0; 
color : #FFFFFF; 
left : 29%; 
text-decoration : none; 
} 
@media only screen and (max-width: 480px) {#i9c1xx {
right : 0; 
left : 0; 
width : 100%; 
top : 30%; 
bottom : 0; 
}} 
@media only screen and (max-width: 992px) {#i9c1xx {
width : 100%; 
left : 0; 
right : 0; 
}} 
#i5fqoc {
padding : 10px; 
min-height : 100px; 
height : 317px; 
position : relative; 
font-family : Times New Roman, Times, serif; 
width : 100%; 
text-decoration : none; 
} 
@media only screen and (max-width: 480px) {#i5fqoc {
width : 100%; 
height : 100%; 
}} 
@media only screen and (max-width: 992px) {#ivbdjv {
height : 338.6px; 
}} 
@media only screen and (max-width: 480px) {#ixzslc {
width : 50%; 
height : 350px; 
min-height : 0; 
}} 
@media only screen and (max-width: 480px) {#iafvpq {
width : 50%; 
height : 350px; 
min-height : 0; 
}} 
@media only screen and (max-width: 480px) {#ijybbx {
width : 50%; 
height : 500px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#ifn8qn {
width : 50%; 
height : 500px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#ivbdjv {
width : 50%; 
height : 525px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#ih1xin {
width : 50%; 
height : 525px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#iky5hi {
width : 50%; 
height : 400px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#ikswhd {
width : 50%; 
height : 400px; 
position : relative; 
}} 
@media only screen and (max-width: 480px) {#i83dhi {
width : 50%; 
height : 250px; 
}} 
@media only screen and (max-width: 480px) {#ittzfj {
height : 2178px; 
}} 
@media only screen and (max-width: 480px) {#ibwuaq {
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#ifh71k {
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#i97qdp {
height : 100%; 
}} 
@media only screen and (max-width: 480px) {#i000fi {
height : 100%; 
}} 
@media only screen and (max-width: 992px) {#iesdb6 {
color : #000000; 
font-size : 28px; 
line-height : 28px; 
text-decoration : none; 
}} 
@media only screen and (max-width: 992px) {#iz6ev7 {
color : #000000; 
font-size : 28px; 
line-height : 28px; 
text-decoration : none; 
}} 
@media only screen and (max-width: 992px) {#ijkf9t {
color : #000000; 
font-size : 28px; 
line-height : 28px; 
text-decoration : none; 
}} 
@media only screen and (max-width: 992px) {#iosw1z {
color : #000000; 
font-size : 28px; 
line-height : 28px; 
text-decoration : none; 
}} 
#iosw1z {
font-size : 28px; 
line-height : 28px; 
color : #000000; 
} 
#iz6ev7 {
color : #000000; 
font-size : 28px; 
line-height : 28px; 
} 
#iesdb6 {
color : #000000; 
line-height : 28px; 
font-size : 28px; 
} 
#ijkf9t {
line-height : 28px; 
font-size : 28px; 
color : #000000; 
} 
#i8lnsg {
white-space : pre-wrap; 
} 
@media only screen and (max-width: 480px) {#irewm9 {
font-size : 20px; 
position : static; 
text-align : left; 
}} 
@media only screen and (max-width: 480px) {#ipo1c9 {
font-size : 20px; 
}} 
#i114ep {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i0a5qg {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#it11um {
padding : 10px; 
min-height : 100px; 
width : 60%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ilkxqj {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
:root {
--hamburgerAnimatedZIndex :  0; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
#menu__toggle:checked ~ .menu__btn {
left : 30px; 
} 
#menu__toggle:checked + .menu__btn > .span_middle {
transform : rotate(45deg); 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black !important; 
} 
#menu__toggle:checked + .menu__btn > .span_before {
top : 5px; 
transform : rotate(45deg); 
} 
#menu__toggle:checked + .menu__btn > .span_after {
top : 0px; 
transform : rotate(135deg); 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black !important; 
} 
#menu__toggle:checked ~ .menu__box {
left : 0px !important; 
} 
.menu__btn {
position : absolute; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 25px; 
height : 2px; 
z-index : 1; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 3px; 
margin-bottom : 3px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.linkhrefs {
position : fixed; 
} 
#i6as7l {
padding : 10px; 
display : block; 
} 
#ix3ge1 {
color : rgb(214, 164, 25); 
font-family : Inika; 
font-size : 20px; 
text-align : center; 
background-color : rgb(41, 42, 46); 
} 
#i4m3vk {
color : black; 
height : 125px; 
} 
#icwl38 {
padding : 10px; 
display : block; 
} 
#igvz8y {
color : rgb(255, 255, 255); 
font-family : Inika; 
font-size : 20px; 
text-align : center; 
background-color : rgb(41, 42, 46); 
} 
@media only screen and (max-width: 480px) {#i6as7l {
display : none; 
}} 
@media only screen and (max-width: 480px) {#icwl38 {
display : none; 
}} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
#i9p48h {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 100%; 
} 
#i1lgcl {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 23px; 
color : rgb(214, 164, 25); 
font-weight : 300; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#iw1nu8 {
min-height : 100px; 
padding : 10px; 
margin : 0 0 15px 0; 
} 
#i6zvkj {
padding : 10px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#iz0uhn {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#izu0u1 {
background-color : transparent; 
width : 50%; 
} 
#iegld9 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#i1wco3 {
background-color : transparent; 
width : 50%; 
} 
#i560gu {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#ippk3u {
background-color : transparent; 
width : 50%; 
} 
#i4lhaq {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#i7i32j {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#i022xn {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#icphip {
__bg-type : color; 
background-image : linear-gradient(#D6A419,#D6A419); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 100px; 
height : 35px; 
border-radius : 15px 15px 15px 15px; 
font-family : Times New Roman, Times, serif; 
} 
#irz3tk {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 5px 0; 
} 
#iwzm9m {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 5px 0; 
} 
#ib0cpq {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 10px 0; 
} 
@media only screen and (max-width: 992px) {#izu0u1 {
color : #D6A419; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ib0cpq {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i1wco3 {
color : #D6A419; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ippk3u {
color : #D6A419; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iwzm9m {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#irz3tk {
width : 100%; 
}} 
#i44cqh {
padding : 10px 5px 10px 5px; 
display : block; 
color : #fff; 
} 
#isxpy4 {
padding : 10px; 
display : block; 
color : #fff; 
} 
#irl48k {
padding : 10px; 
display : block; 
color : #fff; 
} 
.link {
color : #fff; 
} 
#ivwzi2 {
padding : 10px; 
min-height : 100px; 
width : 50%; 
display : flex; 
justify-content : center; 
align-items : center; 
flex-direction : column; 
} 
.gjs-jsCalendar * {
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 0px; 
padding-right : 0px; 
padding-bottom : 0px; 
padding-left : 0px; 
} 
.gjs-jsCalendar table, .gjs-jsCalendar table td, .gjs-jsCalendar table th {
border-top-width : 0px; 
border-right-width : 0px; 
border-bottom-width : 0px; 
border-left-width : 0px; 
border-top-style : initial; 
border-right-style : initial; 
border-bottom-style : initial; 
border-left-style : initial; 
border-top-color : initial; 
border-right-color : initial; 
border-bottom-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
} 
.gjs-jsCalendar table {
background-color : rgb(255, 255, 255); 
border-collapse : collapse; 
border-top-left-radius : 4px; 
border-top-right-radius : 4px; 
border-bottom-right-radius : 4px; 
border-bottom-left-radius : 4px; 
box-shadow : rgba(0, 0, 0, 0.4) 0px 0px 2px; 
color : rgb(0, 0, 0); 
font-family : Tahoma, Geneva, sans-serif; 
margin-top : 5px; 
margin-right : 5px; 
margin-bottom : 5px; 
margin-left : 5px; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-title {
height : 40px; 
line-height : 40px; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-title-left {
float : left; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-title-right {
float : right; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-left, .gjs-jsCalendar thead .gjs-jsCalendar-nav-right {
border-top-left-radius : 10px; 
border-top-right-radius : 10px; 
border-bottom-right-radius : 10px; 
border-bottom-left-radius : 10px; 
color : rgb(153, 153, 153); 
cursor : pointer; 
font-family : "Courier New", Courier, monospace; 
font-size : 12px; 
font-weight : 700; 
height : 20px; 
line-height : 20px; 
margin-top : 10px; 
margin-right : 8px; 
margin-bottom : 10px; 
margin-left : 8px; 
text-align : center; 
transition-duration : 0.2s, 0.2s; 
transition-timing-function : ease, ease; 
transition-delay : 0s, 0s; 
transition-property : color, background-color; 
width : 20px; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(226, 226, 226); 
color : rgb(0, 0, 0); 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-left {
float : left; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-right {
float : right; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-title-name {
cursor : default; 
float : left; 
font-size : 18px; 
font-weight : lighter; 
padding-top : 0px; 
padding-right : 20px; 
padding-bottom : 0px; 
padding-left : 20px; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-left::after {
content : "<"; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-nav-right::after {
content : ">"; 
} 
.gjs-jsCalendar thead .gjs-jsCalendar-week-days th {
text-shadow : rgba(0, 0, 0, 0.2) 0px 0px 1px; 
} 
.gjs-jsCalendar tbody td, .gjs-jsCalendar thead .gjs-jsCalendar-week-days th {
border-top-left-radius : 18px; 
border-top-right-radius : 18px; 
border-bottom-right-radius : 18px; 
border-bottom-left-radius : 18px; 
cursor : default; 
display : inline-block; 
font-size : 12px; 
font-weight : lighter; 
height : 36px; 
line-height : 36px; 
margin-top : 1px; 
margin-right : 2px; 
margin-bottom : 1px; 
margin-left : 2px; 
text-align : center; 
transition-duration : 0.1s, 0.2s; 
transition-timing-function : ease, ease; 
transition-delay : 0s, 0s; 
transition-property : color, background-color; 
width : 36px; 
} 
.gjs-jsCalendar tbody td:hover {
background-color : rgb(230, 230, 230); 
} 
.gjs-jsCalendar tbody td.gjs-jsCalendar-selected {
background-color : rgb(255, 255, 255); 
border-top-width : 2px; 
border-right-width : 2px; 
border-bottom-width : 2px; 
border-left-width : 2px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(230, 230, 230); 
border-right-color : rgb(230, 230, 230); 
border-bottom-color : rgb(230, 230, 230); 
border-left-color : rgb(230, 230, 230); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
box-sizing : content-box; 
height : 32px; 
line-height : 32px; 
width : 32px; 
} 
.gjs-jsCalendar tbody td.gjs-jsCalendar-current {
background-color : rgb(82, 201, 255); 
border-top-left-radius : 18px; 
border-top-right-radius : 18px; 
border-bottom-right-radius : 18px; 
border-bottom-left-radius : 18px; 
color : rgb(255, 255, 255); 
} 
.gjs-jsCalendar tbody td.gjs-jsCalendar-next, .gjs-jsCalendar tbody td.gjs-jsCalendar-previous {
color : rgb(202, 202, 202); 
} 
.gjs-jsCalendar tbody td.gjs-jsCalendar-next:hover, .gjs-jsCalendar tbody td.gjs-jsCalendar-previous:hover {
color : rgb(255, 255, 255); 
} 
.gjs-jsCalendar thead {
display : block; 
margin-top : 4px; 
margin-right : 4px; 
margin-bottom : 0px; 
margin-left : 4px; 
} 
.gjs-jsCalendar tbody {
display : block; 
margin-top : 0px; 
margin-right : 4px; 
margin-bottom : 4px; 
margin-left : 4px; 
} 
.gjs-jsCalendar ::selection {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : rgb(131, 216, 255); 
} 
.gjs-jsCalendar.yellow tbody td.gjs-jsCalendar-current {
background-color : rgb(255, 227, 27); 
} 
.gjs-jsCalendar.yellow ::selection {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : rgb(253, 231, 76); 
} 
.gjs-jsCalendar.orange tbody td.gjs-jsCalendar-current {
background-color : rgb(255, 180, 0); 
} 
.gjs-jsCalendar.orange ::selection {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : rgb(255, 180, 0); 
} 
.gjs-jsCalendar.red tbody td.gjs-jsCalendar-current {
background-color : rgb(246, 81, 29); 
} 
.gjs-jsCalendar.red ::selection {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : rgb(246, 81, 29); 
} 
.gjs-jsCalendar.green tbody td.gjs-jsCalendar-current {
background-color : rgb(127, 184, 0); 
} 
.gjs-jsCalendar.green ::selection {
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : rgb(127, 184, 0); 
} 
.gjs-jsCalendar.material-theme table {
border-top-left-radius : 0px; 
border-top-right-radius : 0px; 
border-bottom-right-radius : 0px; 
border-bottom-left-radius : 0px; 
} 
.gjs-jsCalendar.material-theme thead {
background-color : rgb(82, 201, 255); 
color : rgb(255, 255, 255); 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 4px; 
padding-right : 4px; 
padding-bottom : 0px; 
padding-left : 4px; 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title {
display : block; 
position : relative; 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-name {
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgba(255, 255, 255, 0.4); 
color : rgb(255, 255, 255); 
font-size : 16px; 
left : 15px; 
position : absolute; 
right : 15px; 
text-align : center; 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-left, .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-right {
position : absolute; 
z-index : 1; 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-left, .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-right {
color : rgb(255, 255, 255); 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.material-theme thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(3, 169, 244); 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-title-right {
right : 0px; 
} 
.gjs-jsCalendar.material-theme thead .gjs-jsCalendar-week-days th {
font-size : 14px; 
text-shadow : none; 
} 
.gjs-jsCalendar.material-theme.yellow thead {
background-color : rgb(255, 227, 27); 
} 
.gjs-jsCalendar.material-theme.yellow thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.material-theme.yellow thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(226, 202, 35); 
} 
.gjs-jsCalendar.material-theme.orange thead {
background-color : rgb(255, 180, 0); 
} 
.gjs-jsCalendar.material-theme.orange thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.material-theme.orange thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(212, 150, 0); 
} 
.gjs-jsCalendar.material-theme.red thead {
background-color : rgb(246, 81, 29); 
} 
.gjs-jsCalendar.material-theme.red thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.material-theme.red thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(187, 61, 22); 
} 
.gjs-jsCalendar.material-theme.green thead {
background-color : rgb(127, 184, 0); 
} 
.gjs-jsCalendar.material-theme.green thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.material-theme.green thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(99, 144, 0); 
} 
.gjs-jsCalendar.classic-theme table, .gjs-jsCalendar.classic-theme tbody td, .gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-current, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days th {
border-top-left-radius : 0px; 
border-top-right-radius : 0px; 
border-bottom-right-radius : 0px; 
border-bottom-left-radius : 0px; 
} 
.gjs-jsCalendar.classic-theme thead {
background-color : rgb(82, 201, 255); 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 4px; 
padding-right : 4px; 
padding-bottom : 0px; 
padding-left : 4px; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-row {
display : block; 
width : 100%; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title {
position : relative; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-name {
color : rgb(255, 255, 255); 
font-size : 16px; 
left : 41px; 
position : absolute; 
right : 41px; 
text-align : center; 
text-shadow : none; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-left, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-right {
position : absolute; 
z-index : 1; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-title-right {
right : 0px; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right {
color : rgb(255, 255, 255); 
text-shadow : none; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(3, 169, 244); 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days {
background-color : rgb(255, 255, 255); 
display : block; 
margin-top : 0px; 
margin-right : -4px; 
margin-bottom : 0px; 
margin-left : -4px; 
} 
.gjs-jsCalendar.classic-theme thead .gjs-jsCalendar-week-days th {
font-size : 10px; 
height : 20px; 
line-height : 20px; 
text-shadow : none; 
} 
.gjs-jsCalendar.classic-theme tbody td {
border-left-width : 1px; 
border-left-style : solid; 
border-left-color : rgb(218, 218, 218); 
border-top-width : 1px; 
border-top-style : solid; 
border-top-color : rgb(218, 218, 218); 
margin-top : -1px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : -1px; 
padding-top : 1px; 
padding-right : 1px; 
padding-bottom : 0px; 
padding-left : 1px; 
width : 38px; 
} 
.gjs-jsCalendar.classic-theme tbody {
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
} 
.gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-selected {
background-color : rgb(204, 239, 255); 
border-bottom-width : 0px; 
border-bottom-style : initial; 
border-bottom-color : initial; 
border-left-width : 1px; 
border-left-style : solid; 
border-left-color : rgb(218, 218, 218); 
border-right-width : 0px; 
border-right-style : initial; 
border-right-color : initial; 
border-top-width : 1px; 
border-top-style : solid; 
border-top-color : rgb(218, 218, 218); 
height : 36px; 
line-height : 36px; 
} 
.gjs-jsCalendar.classic-theme tbody td.gjs-jsCalendar-current.gjs-jsCalendar-selected {
background-color : rgb(82, 201, 255); 
text-shadow : rgb(0, 0, 0) 0px 0px 3px; 
} 
.gjs-jsCalendar.classic-theme.yellow thead {
background-color : rgb(255, 227, 27); 
} 
.gjs-jsCalendar.classic-theme.yellow thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.classic-theme.yellow thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(226, 202, 35); 
} 
.gjs-jsCalendar.classic-theme.orange thead {
background-color : rgb(255, 180, 0); 
} 
.gjs-jsCalendar.classic-theme.orange thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.classic-theme.orange thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(212, 150, 0); 
} 
.gjs-jsCalendar.classic-theme.red thead {
background-color : rgb(246, 81, 29); 
} 
.gjs-jsCalendar.classic-theme.red thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.classic-theme.red thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(187, 61, 22); 
} 
.gjs-jsCalendar.classic-theme.green thead {
background-color : rgb(127, 184, 0); 
} 
.gjs-jsCalendar.classic-theme.green thead .gjs-jsCalendar-nav-left:hover, .gjs-jsCalendar.classic-theme.green thead .gjs-jsCalendar-nav-right:hover {
background-color : rgb(99, 144, 0); 
} 
.gjs-scheduleButton {
width : 75px; 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : gray; 
border-right-color : gray; 
border-bottom-color : gray; 
border-left-color : gray; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
background-color : rgb(255, 255, 255); 
cursor : pointer; 
color : rgb(0, 0, 0); 
margin : 10px 0 0 0; 
border-radius : 1px 1px 1px 1px; 
box-shadow : 0px 1px 1px 0px black ; 
} 
.gjs-scheduleButton:active  {
background-color : rgb(214, 164, 25); 
color : rgb(255, 255, 255); 
} 
#iqpgih {
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#56575c 0%, #56575c 100%); 
} 
#ibitak {
padding : 10px; 
display : block; 
color : #e5f0ff; 
text-align : center; 
} 
#i0n2pg {
padding : 10px; 
display : block; 
color : #e5f0ff; 
text-align : right; 
font-size : 13px; 
} 
#i2k0wd {
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#56575c 0%, #56575c 100%); 
width : 320px; 
} 
.gjs-jsCalendar-title-row {
height : 100px; 
} 
.gjs-jsCalendar-current {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
.FormCalendarStyle {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#56575c 0%, #56575c 100%); 
} 
#iektgm {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Times New Roman, Times, serif; 
font-size : 23px; 
color : rgb(214, 164, 25); 
font-weight : 300; 
} 
#i4f95u {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#ijrmlv {
background-color : transparent; 
width : 50%; 
} 
@media only screen and (max-width: 992px) {#ijrmlv {
color : #D6A419; 
width : 100%; 
}} 
#iranhl {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#ipcrm5 {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 5px 0; 
} 
@media only screen and (max-width: 992px) {#ipcrm5 {
width : 100%; 
}} 
#ieaen2 {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#i3vgjd {
background-color : transparent; 
width : 50%; 
} 
@media only screen and (max-width: 992px) {#i3vgjd {
color : #D6A419; 
width : 100%; 
}} 
#im6v92 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#i5vjyg {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 5px 0; 
} 
@media only screen and (max-width: 992px) {#i5vjyg {
width : 100%; 
}} 
#i74usu {
color : #D6A419; 
font-family : Times New Roman, Times, serif; 
} 
#i5pz22 {
background-color : transparent; 
width : 50%; 
} 
@media only screen and (max-width: 992px) {#i5pz22 {
color : #D6A419; 
width : 100%; 
}} 
#iw1t6u {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
width : 100%; 
text-align : center; 
} 
#iomewc {
padding : 0 0 0 0; 
min-height : 0; 
border : 1px solid #D6A419; 
width : 50%; 
margin : 0 0 10px 0; 
} 
@media only screen and (max-width: 992px) {#iomewc {
width : 100%; 
}} 
#ifru4c {
__bg-type : color; 
background-image : linear-gradient(#D6A419,#D6A419); 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
width : 100px; 
height : 35px; 
border-radius : 15px 15px 15px 15px; 
font-family : Times New Roman, Times, serif; 
} 
#isjtam {
padding : 10px; 
display : flex; 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#ix7hpt {
min-height : 100px; 
padding : 10px; 
margin : 0 0 15px 0; 
} 
#izvpd5 {
padding : 10px; 
min-height : 100px; 
width : 100%; 
height : 100%; 
} 
.scheduleBasicStyle {
display : flex; 
flex-direction : column; 
padding-top : 20px; 
padding-right : 20px; 
padding-bottom : 20px; 
padding-left : 20px; 
} 
#iw9lfx {
padding : 10px; 
} 
#i0q3sk {
display : none; 
} 
#ifjbrc {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ii61x5 {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#iwyyfn {
display : flex; 
flex-direction : row; 
justify-content : center; 
width : auto; 
} 
#i9flke {
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
margin : 5px; 
min-height : 200px; 
width : auto; 
} 
#i4yqxo {
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
margin : 5px; 
min-height : 200px; 
} 
#ikzwto {
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
margin : 5px; 
min-height : 200px; 
} 
#in5l8g {
display : flex; 
flex-direction : column; 
justify-content : flex-start; 
margin : 5px; 
min-height : 200px; 
} 
#ic4brd {
padding : 10px; 
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#56575c 0%, #56575c 100%); 
} 
#i2zudm {
font-size : 19px; 
text-align : center; 
color : #e5f0ff; 
} 
#i5ufu7 {
padding : 10px; 
min-height : 100px; 
} 
#i8m1n3 {
padding : 10px 2px 10px 2px; 
min-height : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#56575c 0%, #56575c 100%); 
} 
#iqvrih {
display : none; 
} 
#i0hahr {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#is84w3 {
color : black; 
width : 40px; 
height : 40px; 
} 
#in3wic {
color : black; 
width : 40px; 
height : 40px; 
} 
#i1u7g7 {
padding : 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iryuk2 {
display : none; 
} 
#ic6qaz {
display : flex; 
flex-direction : column; 
padding : 20px; 
align-items : center; 
} 
#ivv32l {
padding : 5px; 
background : transparent; 
border : none; 
border-bottom : 1px solid #000; 
width : 95%; 
margin : 0 0 0px 0; 
color : #d6a419; 
} 
#iu3w7u {
padding : 5px; 
background : transparent; 
border : none; 
border-bottom : 1px solid #000; 
width : 95%; 
margin : 0 0 0px 0; 
color : #d6a419; 
} 
#ij7hzd {
padding : 5px; 
background : transparent; 
border : none; 
border-bottom : 1px solid #000; 
width : 95%; 
margin : 0 0 0px 0; 
color : #d6a419; 
} 
#if44c9 {
padding : 5px; 
background : transparent; 
border : none; 
border-bottom : 1px solid #000; 
width : 95%; 
margin : 0 0 0px 0; 
color : #d6a419; 
} 
#igt5el {
padding : 5px; 
background : transparent; 
border : none; 
border-bottom : 1px solid #000; 
width : 95%; 
margin : 0 0 0px 0; 
color : #d6a419; 
} 
#i5upgl {
padding : 5px 5px; 
text-align : center; 
text-decoration : none; 
border : 1px none #0077CC; 
cursor : pointer; 
width : 180px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
border-radius : 30px 30px 30px 30px; 
} 
#i8xhit {
display : none; 
} 
#ieo9yj {
padding : 40px 40px 40px 40px; 
} 
#ioh78o {
padding : 10px; 
display : block; 
font-size : 24px; 
color : #e5f0ff; 
} 
#iii10p {
padding : 10px; 
display : block; 
color : #e5f0ff; 
font-size : 12px; 
text-align : center; 
} 
#i9pj5l {
padding : 10px; 
width : 100%; 
} 
#ibwxpz {
padding : 10px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : flex-start; 
align-items : center; 
} 
#ijfvy8 {
color : black; 
width : 20px; 
height : 20px; 
} 
#iwh5wu {
display : none; 
} 
#i9sp8y {
padding : 2px 10px 2px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
#ivuvwv {
color : black; 
width : 20px; 
height : 20px; 
} 
#iksuas {
padding : 10px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : flex-start; 
align-items : center; 
} 
#ityts7 {
padding : 2px 10px 2px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
#iogpck {
padding : 10px; 
width : 100%; 
} 
#ikahdi {
color : black; 
width : 20px; 
height : 20px; 
} 
#ip216p {
padding : 10px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : flex-start; 
align-items : center; 
} 
#ic13wi {
padding : 2px 10px 2px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
#i4xpep {
padding : 10px; 
width : 100%; 
} 
#i4xbwk {
color : black; 
width : 20px; 
height : 20px; 
} 
#i391qg {
padding : 10px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : flex-start; 
align-items : center; 
} 
#is6opa {
padding : 2px 10px 2px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
#iaxpig {
padding : 10px; 
width : 100%; 
} 
#i1k3xh {
color : black; 
width : 20px; 
height : 20px; 
} 
#ivsecu {
padding : 10px; 
width : 100%; 
display : flex; 
flex-direction : row; 
justify-content : flex-start; 
align-items : center; 
} 
#izgcv3 {
padding : 2px 10px 2px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d6a419 0%, #d6a419 100%); 
} 
#id3itn {
padding : 10px; 
width : 100%; 
} 
#i07s2f {
display : none; 
} 
#ijcfjt {
display : none; 
} 
#iz7n8z {
display : none; 
} 
#ibndst {
display : none; 
} 

  
  @media only screen and (max-width: 480px) {#ivwzi2 {
width : 100%; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ips5c9 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.auto-jsCalendar.gjs-jsCalendar {
padding : 10px 0px 10px 0px; 
}} 

}
  